import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h4>{`How Does Your Skill Compare?`}</h4>
    <p>{`We analyzed `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/ci/"
      }}>{`Alexa Skill ratings`}</a>{` over the month of November.`}</p>
    <p>{`Of the nearly 24,000 Skills at the end of November, approximately 62% had no ratings at all, and 16% had only one rating.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2018/01/06164801/0_mx5iMLpVBvtwwPY9.png)
    </figure>
The majority of both one star and five star ratings are the result of only one
review. In fact, 70.5% of one star reviews are from a single rating, and 65.6%
of five star reviews are from one rating.
    <p>{`If we remove all the one rating reviews, the distribution starts to look a little clearer:`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2018/01/06164800/0_8TJ_3YinUACQCyNm.png)
    </figure>
However, 95% of the Skills have eight ratings or less. If we look at just the
top 5%, we see the following distribution of ratings, with a peak at 3 stars and
another at 4.9:
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2018/01/06164759/0_GYW9cLbqbIgx038n.png)
    </figure>
### Popular Categories
    <p>{`The top categories of Skills are Games, News, Education, and Music. Of the more than 5,600 Game Skills, only 35% have ratings. Of the approximate 3,400 News Skills, only 25% have ratings, whereas about 35% of the nearly 1,200 Education Skills and 31% of the approximate 740 Music Skills have ratings.`}</p>
    <p>{`Conversely, more than half of the Skills in categories like Novelty and Humor, Lifestyle, and Smart Home, have ratings. In fact, 59% of Smart Home Skills have ratings, averaging about 3.4 stars.`}</p>
    <p>{`Categories with a high percentage of ratings are Home Services (75%), Connected Car (68%), and Utilities (68%), but the number of Skills tends to be much lower as well. For example, three of the four Home Service Skills have ratings.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2018/01/06164758/0_HdhRJcvRj5vkk2E9.png)
    </figure>
Earlier we
[surveyed](https://blog.dashbot.com/the-impact-of-alexa-and-google-home-on-consumer-behavior-d501b04c0ffe)
users of Alexa and Google Home and found Music was the top use case.
Interestingly enough, Music is the fourth most popular category of Skills in
terms of the number of skills, and the category has a 4.2 star rating on
average.
    <p>{`Food and Drink and Utilities tend to have the lowest average ratings, with 2.8 and 2.9 stars respectively.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2018/01/06164757/0_Dud-tFqNFjbUTbQs.png)
    </figure>
### Most Frequently Rated Skills
    <p>{`The most frequently rated Skills for the month of November are below. Many of them are also top rated between 4.7 and 4.9 stars, but interestingly enough, there are some that aren’t very highly rated like the TP Link and Sirius XM Skills.`}</p>
    <p>{`What’s also interesting is, of the most rated skills, six of them are in the Music and Audio Category. Rounding out the top ten are three Games, and only one Smart Home skill.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2018/01/06164756/0_kcJCxIKuuWKhJWuF.png)
    </figure>
### Trending Newcomers
    <p>{`There were over 1,300 new Skills added during the month of November. The following are the ones that received the most ratings:`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2018/01/06164755/0_jzxus0ARr9-mULCs.png)
    </figure>
### How does your Skill Compare?
    <p>{`At Dashbot, in addition to `}<a parentName="p" {...{
        "href": "http://voice.dashbot.io/"
      }}>{`voice analytics`}</a>{`, we provide `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/ci/"
      }}>{`competitive intelligence`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/market-metrics/"
      }}>{`comparison metrics`}</a>{`.`}</p>
    <p>{`We’re excited to announce our new `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/ci"
      }}>{`Alexa Competitive Intelligence`}</a>{` tool.`}</p>
    <p>{`With our tools, developers and brands, can see how their Alexa Skill compares to others. Our `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/market-metrics/"
      }}>{`Market Metrics`}</a>{` reports show comparisons based on interactions and users, and our `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/ci/"
      }}>{`Competitive Intelligence`}</a>{` reports show comparisons based on Alexa Star ratings.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2018/01/06164754/0_8evLxvzryLLjekS8.png)
    </figure>
### About Dashbot
    <p><em parentName="p"><a parentName="em" {...{
          "href": "http://www.dashbot.io/"
        }}>{`Dashbot`}</a>{` is a conversational analytics platform that enables brands and developers to increase engagement, acquisition, and monetization through actionable data and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support Alexa, Google Home, Facebook Messenger, Slack, Twitter, Kik and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      